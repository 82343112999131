<template>
    <form v-on:submit.prevent="validate($event)" novalidate>
      <div v-if="!showThankYouMessage" class="z-1 md:z-0">

        <fieldset :class="{
            'w-full' : inlineLayout,
            'shadow p-2 rounded rounded-default' : showShadow
            }">
            
            <legend class="sr-only">Sign up to newsletter</legend>

            <div aria-live="assertive" aria-atomic="true">
              <p class="sr-only" v-if="showEmailError">Please enter a valid email address</p>
            </div>

            <label
              :for="`signup-email-${source.toLowerCase()}`"
              class="block font-semibold mb-3 overflow-hidden"
              :class="(inlineLayout || reducedVerticalSpacing) ? 'sr-only' : null"
              >
              <span class="flex transform transition-transform duration-200"
                :class="{
                        'translate-x-0' : (showEmailError || showEmailValid),
                        '-translate-x-7' : (!showEmailError && !showEmailValid)
                        }
                ">
                <Icon v-if="showEmailError" name="Error" class="mr-2 text-coral text-coral-default"/>
                <Icon v-if="!showEmailError" name="TickCircle" class="mr-2 text-mint text-mint-default"/>
                Email address
              </span>
            </label>

            <p v-if="showEmailError" class="text-sm font-semibold bg-coral-5 border-coral border-coral-default border-l-4 px-3 py-1 my-2 rounded-r-1">              
              <span>Please enter an email address</span>
            </p>
            <div :class="inlineLayout ? 'flex' : null">
              <input 
              :id="`signup-email-${source.toLowerCase()}`"
              type="email"
              required 
              placeholder="Enter email address" 
              v-model="email"
              @blur="validateEmail"
              class="p-2 border-1 rounded rounded-default border-purple-40" 
              :class="{
                      'border-coral border-coral-default shadow-error' : showEmailError,
                      'w-3/4' : inlineLayout,
                      'w-full' : !inlineLayout
                      }"
              />
          
              <button v-on:click="validate($event)" 
              class="p-2 rounded rounded-default whitespace-pre bg-text-link hover:bg-text-link-dark text-white"
              :class="{
                      'lg:p-0 w-1/4 ml-2' : inlineLayout,
                      'w-full lg:w-4/10 mt-4' : !inlineLayout
                      }"            
              >Sign up</button>
            </div>
        </fieldset>
        
        <p class="text-sm" :class="reducedVerticalSpacing ? 'mt-2 text-center lg:text-left' : 'mt-6'">
          <template v-if="reducedVerticalSpacing && isMobile()">
            <span class="block mb-1">We won't pass your details on.</span>
          </template>
          <template v-else>
            We won't pass your details on to anyone else. By clicking the sign up button you agree to our
          </template>
          <a class="tsm-text-link font-semibold" :class="{'mr-4' : reducedVerticalSpacing && isMobile()}" @click="$generalClick({label: 'signup terms link click'}, $event, '/en-gb/terms/'); $ga4GeneralClick({event: 'email_modal', module: ga4InitialisationSource, action: 'link clicks', content_display: 'terms of use'})" href="/en-gb/terms/">Terms of Use</a><template v-if="!reducedVerticalSpacing || !isMobile()"> and </template><a class="tsm-text-link font-semibold" @click="$generalClick({label: 'signup privacy policy link click'}, $event, '/en-gb/privacy-policy/');$ga4GeneralClick({event: 'email_modal', module: ga4InitialisationSource, action: 'link clicks', content_display: 'privacy policy'})" href="/en-gb/privacy-policy/">Privacy Policy</a><template v-if="!reducedVerticalSpacing || !isMobile()">.</template>
        </p>
      </div>

      <div v-if="showThankYouMessage" class="z-1 md:z-0 w-full">          
          <h2 class="text-l font-semibold">Thank you</h2>

          <p class="mt-2 mb-5">You're now signed up and your first email will be on its way to <strong>{{ email }}</strong> soon.</p>

          <p>
            Incorrect email address?
            <button class="tsm-text-link" v-on:click="showThankYouMessage=false">Try again</button>
          </p>          
      </div>

    </form>
</template>

<script>
import { mapGetters } from 'vuex';
import Icon from "../Icon.vue";
import HelperFunctions from '../../HelperFunctions'

export default {
  name: 'SignupForm',
  components: {
    Icon,
  },
  props: [
    'source',
    'data',
    'initialisationSource',
    'ga4InitialisationSource',
    'modalOpenTimestamp'
  ],
  data (){
    return {
        showThankYouMessage: false,
        email: '',
        emailRegEx: new RegExp('[^@]+@[^@]+\\.[^@]+'),
        showEmailError: false,
        showEmailValid: false,
        modalSubmitTimestamp: null,
        layoutGuide: {
          /* 
          This form component is used in multiple places with different layout requirements.

          Based on the source code specified e.g. <SignupForm source="LandingPage-footer" />, 
          various layout options can be defined to alter the appearance of the form

          inlineLayout - positions the "Sign up" button to the right of the email field on one line
          and omits the email field label
          
          showShadow - adds a box shadow around the form
          
          reducedVerticalSpacing - omits the email field label, reduces some vertical spacing and uses
          shorter legal text

          */
          "LandingPage-footer": {
            inlineLayout: true,
            showShadow: true,
            reducedVerticalSpacing: false
          },
          "CRM-Modal": {
            inlineLayout: false,
            showShadow: false,
            reducedVerticalSpacing: false
          },
          "CRM-Modal-Autolaunched": {
            inlineLayout: false,
            showShadow: false,
            reducedVerticalSpacing: true
          }          
        }
    }
  },
  methods: {
    submit(){
        const cookieExpiryDays = 1000
        this.$generalClick({category: 'Email Signup', label: `Email Signup signed up via ${this.source}`})

        this.modalSubmitTimestamp = Date.now()

        this.$ga4GeneralClick({
          event: 'email_modal',
          module: this.ga4InitialisationSource,
          action: 'sign-up',
          open_time: (this.modalSubmitTimestamp - this.modalOpenTimestamp) / 1000,
          content_display: 'sign-up'
        })

        HelperFunctions.createCookie('signedup', true, cookieExpiryDays);

        this.$store.dispatch('signUpComplete');

        this.showThankYouMessage = true

        const requestOptions = {
          method: 'POST',
          body: JSON.stringify({
              'email_address' : this.email,
              'source': this.source + ' launched by ' + this.initialisationSource + ' on ' + this.channel,
          })
        };

        fetch(`${this.emailApiDomain}`, requestOptions)
        .then(response => response.json())
        .catch(() => {
          //Do nothing, we just need to handle the error and continue.
        })
    },
    validate(){
        if(this.validateEmail()){
            this.submit()
        }
    },
    validateEmail() {
        this.showEmailError = false;
        this.showEmailValid = false;
        if (this.emailRegEx.test(this.email)) {
          this.showEmailError = false;
          this.showEmailValid = true;
          return true;
        } else {
          this.showEmailError = true;
          this.showEmailValid = false;
          this.$ga4GeneralClick({
            event: 'email_modal',
            module: this.ga4InitialisationSource,
            action: 'enter email',
            validation: 'email address invalid'
          })
          return false;
        }
    },
    isMobile() {
        return (this.layout === 'mobile');
    }
  },
  mounted () {

    const emailApiDomainLive = 'https://gateway.travelsupermarket.com/email/v1/subscribe';
    const emailApiDomainTest = 'https://staging-gateway.travelsupermarket.com/email/v1/subscribe';
    this.emailApiDomain = HelperFunctions.isProd() ? emailApiDomainLive : emailApiDomainTest;
  },
  computed: {
    ...mapGetters(['layout', 'channel']),
    inlineLayout() {
      return this.layoutGuide[this.source].inlineLayout;
    },
    showShadow() {
      return this.layoutGuide[this.source].showShadow;
    },
    reducedVerticalSpacing() {
      return this.layoutGuide[this.source].reducedVerticalSpacing;
    }
  }
}
</script>
