<template>
  <transition name="modal-fade">
    <div
      v-show="isModalVisible"
      class="w-screen h-full m-0 fixed overflow-auto top-0 left-0 z-1000 flex items-center justify-center bg-black-alpha-5"
      :class="minimalLayout ? 'p-4' : 'p-8'"
      @keydown="keydown($event)"      
      ref="crm-modal"
      role="dialog"
    >
      <div @click="closeModal()" class="w-full h-full absolute"></div>
      <div
        class="crm-modal m-auto w-full md:w-9/12 lg:w-7/12 bg-gradient-to-b from-white to-purple-10 p-4 lg:p-7 relative lg:min-h-1/4 scrolling-touch rounded rounded-default overflow-hidden"
        :class="{'crm-modal-full' : !minimalLayout}"
      >
        <button
          ref="close"
          class="bg-white p-0 top-0 left-0 mt-3 ml-3 absolute z-1 text-black"
          aria-label="Close"
          @click="closeModal()"
        >
        <Icon
          name="Close"
          class="w-6 h-6"
        ></Icon>
          
        </button>
        <div class="relative h-full md:w-1/2">
          <h2 
            class="pt-6 lg:pt-4 pb-4 font-semibold lg:w-3/4"
            :class="minimalLayout ? 'text-lg md:text-2xl' : 'text-xl md:text-2xl'"
            >
              Sign up and save on your next holiday
              <span v-if="!minimalLayout" class="block mt-2 h-1 w-8 rounded rounded-default bg-orange"></span>
          </h2>
          <template v-if="!minimalLayout">
            <p class="text-lg">Be a savvy traveller and get:</p>
            <ul class="tick-bullets mt-2 mb-4">
                <li class="mb-2 text-lg flex"><Icon name="TickCircle" class="mr-2 text-orange text-orange-default"/><p><strong class="font-semibold">Top deals</strong> to your inbox</p></li>
                <li class="mb-2 text-lg flex"><Icon name="TickCircle" class="mr-2 text-orange text-orange-default"/><p>Expert <strong class="font-semibold">travel advice</strong></p></li>
                <li class="mb-2 text-lg flex"><Icon name="TickCircle" class="mr-2 text-orange text-orange-default"/><p>The chance to <strong class="font-semibold">win holidays</strong></p></li>
            </ul>
          </template>
          <SignupForm
            :source="signupSource"
            :initialisationSource="initialisationSource"
            :ga4InitialisationSource="ga4InitialisationSource"
            :modalOpenTimestamp="modalOpenTimestamp"
          />
        </div>
        <div 
          class="h-12 md:h-auto -mx-6 md:mx-0" 
          :class="{'hidden lg:block' : minimalLayout}
          ">
          <img
              class="masked-hero md:absolute left-1/2 md:ml-6 mt-4 md:-mt-12"
              loading="lazy"
              aria-hidden="true"
              :src="`${prodUrl}/cdn-cgi/image/f=auto,width=600,height=600,fit=cover,quality=75${image}`"
              :srcset="`${prodUrl}/cdn-cgi/image/f=auto,width=600,height=600,fit=cover,quality=75${image} 1x, ${prodUrl}/cdn-cgi/image/width=1200,height=1200,fit=cover,quality=75${image} 2x`"
              alt=""
              width="493"
              height="480"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import SignupForm from './signup/SignupForm.vue'
import Icon from "./Icon.vue";
import HelperFunctions from '../HelperFunctions'
import { mapGetters } from "vuex";

export default {
  components: {
    SignupForm,
    Icon
  },
  data() {
    return {
      signupSource: 'CRM-Modal',
      signupSourceAutolaunched: 'CRM-Modal-Autolaunched',
      initialisationSource: 'nav click',
      ga4InitialisationSource: '',
      minimalLayout: false,
      isModalVisible: false,
      autolaunchDelay: 10000,
      scrollTriggerPercentage: 30,
      prodUrl: 'https://www.travelsupermarket.com', // Cloudflare images always need to be served from the live domain,
      modalOpenTimestamp: null,
      modalCloseTimestamp: null
    }
  },
  props: {
    data: Object,
    image: {
        default: "/sonic/image/source/square/beach-huts.jpg",
        type: String
    }    
  },
  mounted() {
    if (this.data && this.data.autoLaunch) { 
      this.minimalLayout = true;
      this.autoLaunchModal();
    }

    if (this.data && this.data.triggerOnScroll) {   
      this.minimalLayout = true;
      this.launchOnScroll();
    }
},
  methods: {
    launchOnScroll() {
      if ('IntersectionObserver' in window) {
        setTimeout(() => {
          const triggerPos = (document.body.scrollHeight / 100) * this.scrollTriggerPercentage;
          const triggerElement = document.createElement('div');
          triggerElement.classList.add("crm-modal-trigger","pointer-events-none","absolute");
          triggerElement.style.top = `${triggerPos}px`;

          document.body.appendChild(triggerElement);

          const triggerObserver = new IntersectionObserver(
            (entries) => {
              const elem = entries[0]
              if (!elem.isIntersecting) return
              triggerObserver.unobserve(elem.target)
              if(this.modalShouldDisplay()) {
                this.initialisationSource = 'scroll';
                this.ga4InitialisationSource = 'Scroll Auto Launch'
                this.openModal(null, null, 'auto open');
              }
            }
          )
          triggerObserver.observe(document.querySelector('.crm-modal-trigger'));          
        }, 1000);
      }
    },
    autoLaunchModal() {      
      this.initialisationSource = `${this.autolaunchDelay/ 1000} second timer autolaunch`;
      this.ga4InitialisationSource = 'autolaunch'
      if(this.autolaunchDelay > 0) {
        setTimeout(() => {
            if(this.modalShouldDisplay()) {
              this.openModal(null, null, 'auto open');
            }
        }, this.autolaunchDelay);
      } else {
        if(this.modalShouldDisplay()) {
          this.openModal(null, null, 'auto open');
        }
      }
    },
    modalShouldDisplay(){
      const urlSearchParams = new URLSearchParams(window.location.search);
      if(
        urlSearchParams.get("source") !== "TIV"
        && !HelperFunctions.readCookie("crm-modal-visible")
        && !HelperFunctions.readCookie("signedup")
        && !HelperFunctions.readCookie("mute-crm-modal")
        && HelperFunctions.readCookie("OptanonAlertBoxClosed")      
      )
      {
        return true
      }
    },
    keydown(evt){
      let isEscPressed = evt.key === 'Escape' || evt.keyCode === 27;
      let isTabPressed = evt.key === 'Tab' || evt.keyCode === 9;

      if (isEscPressed) {
        this.closeModal();
      }

      if (!isTabPressed) {
        return
      }
      
      if (evt.shiftKey) { // if shift key pressed for shift + tab combination
        if (document.activeElement === this.focusableEls[0]) {
          this.focusableEls[this.focusableEls.length - 1].focus()
          evt.preventDefault()
        }
      } else { // if tab key is pressed
        if (document.activeElement === this.focusableEls[this.focusableEls.length - 1]) {
          this.focusableEls[0].focus()
          evt.preventDefault()
        }
      }
    },
    getFocusableEls() {
      this.focusableEls = this.$refs['crm-modal'].querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
    },
    setSourceParameter() {
      if (this.data && this.data.autoLaunch) {
        this.signupSource = this.signupSourceAutolaunched;
      }
    },
    openModal(gaLabel, ga4Label, action) {
      if(gaLabel){
        this.initialisationSource = gaLabel
      }
      if(ga4Label){
        this.ga4InitialisationSource = ga4Label
      }
      this.modalOpenTimestamp = Date.now()
      HelperFunctions.createCookie('crm-modal-visible', true);
      this.$generalClick({category: 'CRM Modal', label: `Opened CRM Modal launched by ${this.initialisationSource}`})
      this.$ga4GeneralClick({
        event: 'email_modal',
        module: this.ga4InitialisationSource,
        action: action ? action : 'open'
      })
      this.setSourceParameter();
      this.isModalVisible = true;
      this.getFocusableEls();
      setTimeout(() => {
        this.$refs.close.focus();
      }, 100);
    },
    closeModal() {
      this.modalCloseTimestamp = Date.now()
      this.$generalClick({category: 'CRM Modal', label: `Closed CRM Modal launched by ${this.initialisationSource}`})
      this.$ga4GeneralClick({
        event: 'email_modal',
        module: this.ga4InitialisationSource,
        action: 'close',
        open_time: (this.modalCloseTimestamp - this.modalOpenTimestamp) / 1000
      })
      this.isModalVisible = false;
      HelperFunctions.createCookie('mute-crm-modal', true, 10);
      HelperFunctions.createCookie('crm-modal-visible', false, -1);
    },
  },
  computed: {
    ...mapGetters(["modalName"])
  }
};
</script>

<style scoped lang="scss">
.modal-fade-enter,
.modal-fade-leave-to {
  @apply opacity-0
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  @apply transition-opacity duration-500
}

.crm-modal {
  max-width: 720px;
}

.crm-modal-full::before {
  content: "";
  display: block;
  position: absolute;
  background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 1440 263.46"><defs><linearGradient id="a" x1="-.07" y1="1939.49" x2=".82" y2="1939.49" gradientTransform="matrix(1440 0 0 -201.48 265 390921.02)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="%23f5f4f5"/><stop offset="1" stop-color="%23adabb4"/></linearGradient><linearGradient id="b" x1="-.18" y1="1939.55" x2=".82" y2="1939.55" gradientTransform="matrix(1440 0 0 -192.81 265 374119.54)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="%23f8bf99"/><stop offset="1" stop-color="%23ee6000"/></linearGradient><linearGradient id="c" x1="-.18" y1="1939.3" x2=".81" y2="1939.3" gradientTransform="matrix(1440 0 0 -230.97 265 448035.26)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="%2384808f"/><stop offset="1" stop-color="%23322c44"/></linearGradient></defs><path fill="%23fcfbfc" d="M0 54c309.89 181.71 817.2 226.2 1220 92.12 83.81-27.92 157.32-61.59 220-99.67V.44H0Z" style="fill-rule:evenodd"/><path d="M1272 163.81C850.82 304 319.1 255.27 0 62v63.61c370.08 168.27 858.1 179.84 1262.74 49.27 64.36-22.1 123.62-47.48 177.26-75.48v-5.47c-50.54 25.98-106.63 49.43-168 69.88Z" style="fill:url(%23a);fill-rule:evenodd"/><path data-name="Path" d="M1272 165.81c61.41-20.45 117.51-43.9 168-69.88V53.45c-62.64 38-136.16 71.75-220 99.67C817.24 287.2 309.9 242.71 0 61v3c319.12 193.27 850.87 242 1272 101.81Z" style="fill:url(%23b);fill-rule:evenodd"/><path data-name="Path" d="M1170.86 137.07C783.72 268.09 295.49 223.52 0 44.35V61c309.89 181.71 817.2 226.2 1220 92.12 83.81-27.92 157.32-61.59 220-99.67V0c-70.85 53.6-161.06 100.49-269.14 137.07Z" style="fill:url(%23c);fill-rule:evenodd"/></svg>');
  transform: rotate(26deg);
  height: 300px;
  width: 1740px;
  margin: -430px 0 0 -1020px;

  @screen md {
    @apply hidden;
  }
}

.masked-hero {
  width: 400px;
  height: 400px;
  mask-image: url('data:image/svg+xml;utf8,<svg width="480" height="480" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480 480"><path fill="white" opacity="0.3"  d="M.8,220.49c4.09,74.59,22.69,138.28,66.51,193.17,44,55.2,114.91,71.9,187.27,64.8C400.46,464.16,468,415.36,479.24,266S376.89,32.71,232.57,6.61C75-21.79-9.23,39.81.8,220.49Zm68,18c0-92.69,73.13-167.78,163.4-167.78s163.41,75.09,163.41,167.78S322.45,406.27,232.18,406.27,68.78,331.17,68.78,238.48Z"/><path fill="white" opacity="0.3" d="M110.55,410.86c93.1,79.9,206.84,63.5,285.52-31.09,39.25-47.2,44.8-117.49,41.39-175.88C434.05,145.09,389.16,94.6,337,63.6,231.69,1.11,154.47,10.51,75.79,105.1S17.36,331.07,110.55,410.86ZM68.78,238.48c0-92.69,73.13-167.78,163.4-167.78s163.41,75.09,163.41,167.78S322.45,406.27,232.18,406.27,68.78,331.17,68.78,238.48Z"/><ellipse fill="white" cx="232.18" cy="238.48" rx="163.41" ry="167.78"/></svg>');
  mask-size: cover;

  @screen md {
    width: 600px;
    height: 600px;
    top: -10%;
  }
}
</style>