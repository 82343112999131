var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[(!_vm.showThankYouMessage)?_c('div',{staticClass:"z-1 md:z-0"},[_c('fieldset',{class:{
        'w-full' : _vm.inlineLayout,
        'shadow p-2 rounded rounded-default' : _vm.showShadow
        }},[_c('legend',{staticClass:"sr-only"},[_vm._v("Sign up to newsletter")]),_c('div',{attrs:{"aria-live":"assertive","aria-atomic":"true"}},[(_vm.showEmailError)?_c('p',{staticClass:"sr-only"},[_vm._v("Please enter a valid email address")]):_vm._e()]),_c('label',{staticClass:"block font-semibold mb-3 overflow-hidden",class:(_vm.inlineLayout || _vm.reducedVerticalSpacing) ? 'sr-only' : null,attrs:{"for":("signup-email-" + (_vm.source.toLowerCase()))}},[_c('span',{staticClass:"flex transform transition-transform duration-200",class:{
                    'translate-x-0' : (_vm.showEmailError || _vm.showEmailValid),
                    '-translate-x-7' : (!_vm.showEmailError && !_vm.showEmailValid)
                    }},[(_vm.showEmailError)?_c('Icon',{staticClass:"mr-2 text-coral text-coral-default",attrs:{"name":"Error"}}):_vm._e(),(!_vm.showEmailError)?_c('Icon',{staticClass:"mr-2 text-mint text-mint-default",attrs:{"name":"TickCircle"}}):_vm._e(),_vm._v("\n            Email address\n          ")],1)]),(_vm.showEmailError)?_c('p',{staticClass:"text-sm font-semibold bg-coral-5 border-coral border-coral-default border-l-4 px-3 py-1 my-2 rounded-r-1"},[_c('span',[_vm._v("Please enter an email address")])]):_vm._e(),_c('div',{class:_vm.inlineLayout ? 'flex' : null},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"p-2 border-1 rounded rounded-default border-purple-40",class:{
                  'border-coral border-coral-default shadow-error' : _vm.showEmailError,
                  'w-3/4' : _vm.inlineLayout,
                  'w-full' : !_vm.inlineLayout
                  },attrs:{"id":("signup-email-" + (_vm.source.toLowerCase())),"type":"email","required":"","placeholder":"Enter email address"},domProps:{"value":(_vm.email)},on:{"blur":_vm.validateEmail,"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('button',{staticClass:"p-2 rounded rounded-default whitespace-pre bg-text-link hover:bg-text-link-dark text-white",class:{
                  'lg:p-0 w-1/4 ml-2' : _vm.inlineLayout,
                  'w-full lg:w-4/10 mt-4' : !_vm.inlineLayout
                  },on:{"click":function($event){return _vm.validate($event)}}},[_vm._v("Sign up")])])]),_c('p',{staticClass:"text-sm",class:_vm.reducedVerticalSpacing ? 'mt-2 text-center lg:text-left' : 'mt-6'},[(_vm.reducedVerticalSpacing && _vm.isMobile())?[_c('span',{staticClass:"block mb-1"},[_vm._v("We won't pass your details on.")])]:[_vm._v("\n        We won't pass your details on to anyone else. By clicking the sign up button you agree to our\n      ")],_c('a',{staticClass:"tsm-text-link font-semibold",class:{'mr-4' : _vm.reducedVerticalSpacing && _vm.isMobile()},attrs:{"href":"/en-gb/terms/"},on:{"click":function($event){_vm.$generalClick({label: 'signup terms link click'}, $event, '/en-gb/terms/'); _vm.$ga4GeneralClick({event: 'email_modal', module: _vm.ga4InitialisationSource, action: 'link clicks', content_display: 'terms of use'})}}},[_vm._v("Terms of Use")]),(!_vm.reducedVerticalSpacing || !_vm.isMobile())?[_vm._v(" and ")]:_vm._e(),_c('a',{staticClass:"tsm-text-link font-semibold",attrs:{"href":"/en-gb/privacy-policy/"},on:{"click":function($event){_vm.$generalClick({label: 'signup privacy policy link click'}, $event, '/en-gb/privacy-policy/');_vm.$ga4GeneralClick({event: 'email_modal', module: _vm.ga4InitialisationSource, action: 'link clicks', content_display: 'privacy policy'})}}},[_vm._v("Privacy Policy")]),(!_vm.reducedVerticalSpacing || !_vm.isMobile())?[_vm._v(".")]:_vm._e()],2)]):_vm._e(),(_vm.showThankYouMessage)?_c('div',{staticClass:"z-1 md:z-0 w-full"},[_c('h2',{staticClass:"text-l font-semibold"},[_vm._v("Thank you")]),_c('p',{staticClass:"mt-2 mb-5"},[_vm._v("You're now signed up and your first email will be on its way to "),_c('strong',[_vm._v(_vm._s(_vm.email))]),_vm._v(" soon.")]),_c('p',[_vm._v("\n        Incorrect email address?\n        "),_c('button',{staticClass:"tsm-text-link",on:{"click":function($event){_vm.showThankYouMessage=false}}},[_vm._v("Try again")])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }